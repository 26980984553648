<template>
	<div class="dlscreen">
		<el-dialog draggable width="85%" top="5vh" :destroy-on-close="true" v-model="drawer" title="联合协办信息">
			<div class="list">
				<!-- 主办人 -->
				<div class="li" v-for="(item,index) in blist" :key="index" v-if="blist.length>0">
					<div class="left">
						<img v-if="item.user?item.user.avatar:false" :src="item.user?item.user.avatar:''" />
						<img v-else src="@/assets/img/tx.png" />
					</div>
					<div class="right">
						<div class="item">
							<p v-if="item.union_status==7">工单状态：<el-tag type="success">{{statelh(item.union_status)}}
								</el-tag>
								<el-button @click="unionsee(item)" class="le-mini" size="mini">查看反馈
								</el-button>
							</p>
							<p>主办人员：{{item.user?item.user.name:''}}</p>
							<p>手机号码：{{item.user?item.user.phone:''}}</p>
							<p>{{$store.state.userinfo.type==0?'乡镇':'部门'}}：
								<span>{{item.user.town.name}}{{item.user.village.name}}</span>
							</p>

							<p>职位：{{fntype(item.user?item.user.position_type:'')}}</p>
							<p>申请时间：{{item.create_time}}</p>
							<p>地址：{{item.user.county?item.user.county.name:""}}{{item.user.town?item.user.town.name:""}}{{item.user.village?item.user.village.name:""}}
								<span v-if="item.user.street.length>0" v-for="(items,indexs) in item.user.street"
									:key="indexs">{{items.name}}</span>
							</p>
							<p>申请联合原因：{{item.union_reason}}</p>
						</div>
					</div>
				</div>
				<!-- 联合人 -->
				<div class="li" v-if="list.length>0" v-for="(item,index) in list" :key="index">
					<div class="left">
						<img v-if="item.user?item.user.avatar:false" :src="item.user?item.user.avatar:''" />
						<img v-else src="@/assets/img/tx.png" />
					</div>
					<div class="right">
						<div class="list">
							<p>工单状态：<el-tag type="success">{{statelh(item.union_status)}}</el-tag>
								<el-button v-if="item.union_status==7" @click="unionsee(item)" class="le-mini"
									size="mini">查看反馈
								</el-button>
							</p>
							<p>协办单位：{{item.user?item.user.name:''}}</p>
							<p>手机号码：{{item.user?item.user.phone:''}}</p>
							<p>{{$store.state.userinfo.type==0?'乡镇':'部门'}}：
								<span>
								<span v-if="item.user.town.length>0" v-for="(items,indexs) in item.user.town">{{items.name}}/</span>
								{{item.user.village?item.user.village.name:''}}</span>
							</p>
							<p>职位：{{fntype(item.user?item.user.position_type:'')}}</p>
							<p>申请时间：{{item.create_time}}</p>
							<p>地址：{{item.user.county?item.user.county.name:""}}{{item.user.town?item.user.town.name:""}}{{item.user.village?item.user.village.name:""}}
								<span v-if="item.user.street.length>0" v-for="(items,indexs) in item.user.street"
									:key="indexs">{{items.name}}</span>
							</p>
							<p>申请联合原因：{{item.union_reason}}</p>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="drawer = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 查看 -->
		<Unionsee ref="unionsee"></Unionsee>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/work";
	import Unionsee from '@/components/unionsee.vue'
	export default {
		components: {
			Unionsee
		},
		data() {
			return {
				drawer: false,
				list: [],
				blist: [],
				name: '',
			}
		},

		methods: {
			// 查看
			unionsee(val) {
				this.$refs.unionsee.show(val, this.name)
			},
			// 类型
			fntype(val) {
				if (val == 0) {
					return "督查室";
				} else if (val == 1) {
					return "县纪委";
				} else if (val == 2) {
					return "区域网格总长";
				} else if (val == 3) {
					return "区域网格长";
				} else if (val == 4) {
					return "网格长";
				} else if (val == 5) {
					return "网格员";
				}
			},
			// 联合状态
			statelh(val) {
				if (val == 0) {
					return "无";
				} else if (val == 1) {
					return "待同意";
				} else if (val == 2) {
					return "已同意";
				} else if (val == 3) {
					return "申请退回审核中";
				} else if (val == 4) {
					return "退回通过";
				} else if (val == 5) {
					return "退回驳回";
				} else if (val == 7) {
					return "已完成";
				}
			},
			show(val) {
				this.blist = val.directOrderList
				this.list = val.unionOrderList
				this.name = val.name
				this.drawer = true
			}
		}
	}
</script>

<style scoped lang="scss">
	.le-mini {
		margin-left: 10px;
	}

	.list {
		width: 100%;
		max-height: 600px;
		overflow-y: auto;
	}

	.li {
		width: 100%;
		margin-bottom: 20px;
		display: flex;

		.right {
			flex: 1;
			padding-left: 20px;

			p {
				margin-top: 10px;
			}


		}

		.left {
			img {
				width: 60px;
				height: 60px;
				border-radius: 50%;
			}
		}
	}
</style>
