<template>
	<div>
		<el-dialog draggable width="85%" top="5vh" :destroy-on-close="true" v-model="drawer" title="查看反馈">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="120px">
					<el-form-item label="工单名称" prop="name">
						<span class="xx"></span>
						<el-input type="text" v-model="name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="主办方" prop="name">
						<span class="xx"></span>
						<el-input type="text" v-model="setdata.user.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="职位" prop="name">
						<span class="xx"></span>
						<div class="dialog-div">{{fntype(setdata.user.position_type)}}</div>
					</el-form-item>
					<el-form-item :label="$store.state.userinfo.type==0?'乡镇':'部门'" prop="name">
						<span class="xx"></span>
						<div class="dialog-div">
						<span v-if="setdata.user.town" v-for="(item,indexs) in setdata.user.town">{{item.name}}/</span>
						{{setdata.user.village?setdata.user.village.name:''}}</div>
					</el-form-item>
					<el-form-item label="描述" prop="name">
						<span class="xx"></span>
						<el-input type="textarea" v-model="setdata.complete" placeholder="">
						</el-input>
					</el-form-item>
					<div class="dialog-title">文件</div>
					<el-form-item label="图片">
						<div class="dialog-up">
							<div class="dialog-img-box" v-for="(item,index) in fulist(setdata.pic_list)" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="fulist(setdata.pic_list)"
									:initial-index="index" fit="fill">
								</el-image>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="附件">
						<div class="dialog-up dialog-upf">
							<!-- 文件 -->
							<el-button v-if="setdata.appendix" @click="download(setdata.appendix)" size="mini">
								{{setdata.appendix}}
								下载
							</el-button>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="drawer = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	export default {
		props: ['title', 'complete'],
		data() {
			return {
				drawer: false,
				setdata: {},
				name: ''
			}
		},
		methods: {
			// 类型
			fntype(val) {
				if (val == 0) {
					return "督查室";
				} else if (val == 1) {
					return "县纪委";
				} else if (val == 2) {
					return "区域网格总长";
				} else if (val == 3) {
					return "区域网格长";
				} else if (val == 4) {
					return "网格长";
				} else if (val == 5) {
					return "网格员";
				}
			},
			// 图片数组
			fulist(val) {
				if (val) {
					return val.split(",")
				} else {
					return ""
				}
			},
			// 文件下载
			download(url, name) {
				window.location.href = url
			},
			show(val, name) {
				this.setdata = val
				this.name = name
				this.drawer = true
			}
		}
	}
</script>

<style scoped lang="scss">
	.dialog-up {
		button {
			margin-bottom: 10px;
		}

		.top {
			font-size: 12px;
			position: absolute;
			top: 0;
			height: 20px;
			line-height: 20px;
			right: 0;

			i {
				padding: 3px;
				cursor: pointer;
				font-size: 14px;
				margin-left: 5px;
				color: #FFFFFF;
				background: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.dialog-title {
		margin: 40px 0;
		font-size: 16px;
	}

	.dialog-up {
		margin-top: 7px;
	}

	.dialog-upf {
		margin-top: 7px;
	}

	.seeupload {
		margin-right: 20px;
	}

	.seeupload span {
		cursor: pointer;
		font-size: 12px;
	}

	.seeupload span:hover {
		color: #5473E8;
	}

	.dialog-img-box {
		position: relative;
		height: 80px;
		overflow: hidden;
		width: 80px;
		margin-right: 15px;
		border: 1px solid #dcdde0;
		border-radius: 4px;
		text-align: center;
	}

	.dialog-img-box p {
		font-size: 12px;
		cursor: pointer;
		width: 80px;
		position: absolute;
		bottom: 0;
		height: 20px;
		line-height: 20px;
		left: 0;
		text-align: center;
		background: rgba(0, 0, 0, 0.5);
		color: #FFFFFF;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
</style>
